import React, { useState } from "react";

import "../css/Loading.css";

export function Loading() {
  const [waitText, setWaitText] = useState("");

  setTimeout(() => {
    setWaitText(
      "Please wait while the server boots, this may take around 30 seconds..."
    );
  }, 2000);

  // three inner divs b/c of the three dots
  return (
    <div className="loading-container">
      <div className="loading">
        <div>.</div>
        <div>.</div>
        <div>.</div>
      </div>
      <p>{waitText}</p>
    </div>
  );
}
